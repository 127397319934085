import { bool, shape, string } from 'prop-types';
import React from 'react';
import { Link } from '@one-thd/sui-atomic-components';
import { Prop65 } from './components/Prop65';

export const isEventBusCreated = () => {
  return typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS;
};

export const publish = ({ targetName = null, eventName = undefined }) => {
  const eventData = {
    component: 'info-and-guides',
    section: 'product-overview',
    targetName
  };

  if (isEventBusCreated) {
    window.LIFE_CYCLE_EVENT_BUS.trigger(eventName, eventData);
  }

  return true;
};

export const productOverviewPropTypes = {
  headingTitle: string,
  hideRichContents: bool,
  preview: bool,
  quickLinks: bool,
  showRichContentsOnly: bool,
  itemId: string.isRequired,
  showReturnMessage: bool,
  revJet: bool,
  showPla: bool,
  isUsingAccordion: bool,
  forceMobile: bool
};

export const productOverviewDefaultProps = {
  headingTitle: 'Product Overview',
  hideRichContents: false,
  preview: false,
  quickLinks: false,
  showRichContentsOnly: false,
  richContentsOnlyRef: null,
  showReturnMessage: false,
  revJet: false,
  showPla: false,
  isUsingAccordion: false,
  forceMobile: false
};

export const isRichContentExpandable = (richContentsOnlyRef) => {
  const richContentMaxHeight = 500;
  return (richContentsOnlyRef
    && richContentsOnlyRef.current
    && richContentsOnlyRef.current.offsetHeight > richContentMaxHeight);
};

export const loadImage = (url) => {
  return new Promise((resolve, reject) => {
    let image = new Image();
    image.src = url;
    image.onload = () => resolve(image);
    image.onerror = () => reject(image);
  });
};

export const richContentBulletFilter = (item = []) => {
  const regex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/gi;
  return String(typeof item === 'string' ? item : item.value).match(regex);
};

export const entry = shape({
  name: string,
  value: string,
  bulletedAttr: bool,
  guid: string
});

export const sortByKeyValue = (key) => {
  return (first, second) => (first[key] > second[key]) - (first[key] < second[key]);
};

export const triggerCardClick = (cardOnClick) => {
  if ((isEventBusCreated())) {
    window.LIFE_CYCLE_EVENT_BUS.on('product-overview.card-click', ({ output }) => {
      const { scrollToBottom = false } = output;
      cardOnClick(this, 'default-overlay', scrollToBottom);
    });
  }
};

export const setRichContentExpandable = (
  {
    ref,
    timer,
    richContentsOnlyOnImageComplete,
    removeRichContentExpandableTimer
  }) => {
  if (ref
    && ref.current
    && ref.current.querySelector) {
    // interval is added only because etch has been added after the DOM creation
    // eslint-disable-next-line
    timer = setInterval(() => {
      const image = ref.current.querySelector('img');
      const imageSrc = image ? image.src : null;
      if (imageSrc) {
        loadImage(imageSrc)
          .then(richContentsOnlyOnImageComplete, removeRichContentExpandableTimer);
      }
    }, 1000);
  }
};

export const getBullets = ({
  channel,
  descriptiveAttributes,
  showRichContentsOnly,
  showReturnMessage,
  prop65Warning,
  prop65Message,
  returnable
}) => {
  let bullets = (descriptiveAttributes || []).filter((attr) => attr.bulleted);
  if (showRichContentsOnly) {
    bullets = bullets
      .filter(richContentBulletFilter);
  }
  if (showReturnMessage && returnable !== 'Non-Returnable') {
    bullets = [...bullets, {
      name: 'Return Policy',
      value: (
        <Link href="https://www.homedepot.com/c/Return_Policy" target="_blank">
          Return Policy
        </Link>
      ),
      bulleted: true
    }];
  }

  bullets.sort(sortByKeyValue('name'));

  // we do not want this prop65 warning to be sorted with the rest
  if (prop65Warning) {
    bullets = [...bullets, {
      name: 'AAA_prop65',
      value: <Prop65 prop65Message={prop65Message} />,
      bulleted: true
    }];
  }
  return bullets;
};

export const themeProperties = {
  showUpcCode: bool
};

export const defaultThemeProperties = {
  showUpcCode: false
};

export const shouldDisplayAdditionalResources = (resources, brandName, brandMainPageUrl) => Boolean(
  resources?.infoAndGuides?.length
  || resources?.diyProjects?.length
  || resources?.installationAndRentals?.length
  || (brandName && brandName.toLowerCase() !== 'unbranded' && brandMainPageUrl)
);