import React, { useEffect, useContext } from 'react';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { useLifeCycleEventBusLifeCycle } from '@thd-olt-functional/utils';
import { ProductOverviewDesktop } from '../../ProductOverviewDesktop';
import { ProductOverviewMobile } from '../../ProductOverviewMobile';
import {
  defaultThemeProperties,
  productOverviewDefaultProps,
  productOverviewPropTypes,
  themeProperties,
} from '../../helpers';
import { dataModel } from '../../dataModel';
import { track } from '../../product-overview-analytics';
import { PLA_FEATURE_SWITCH } from '../../constants';

export const ProductOverviewAccordionBodyComponent = (props) => {
  const {
    itemId,
    showRichContentsOnly,
    hideRichContents,
    preview,
    quickLinks,
    showReturnMessage,
    revJet,
    forceMobile
  } = props;

  const { channel } = useContext(ExperienceContext);
  const showSponsoredPla = useConfigService(PLA_FEATURE_SWITCH) || false;

  useEffect(() => {
    track('accordion-open');
  }, []);

  return (
    <div
      data-section="product-overview"
      data-component="ProductOverviewAccordionBody"
      className="sui-w-full"
    >
      {!forceMobile && channel === 'desktop' ? (
        <ProductOverviewDesktop
          itemId={itemId}
          isUsingAccordion
          showRichContentsOnly={showRichContentsOnly}
          quickLinks={quickLinks}
          hideRichContents={hideRichContents}
          preview={preview}
          showReturnMessage={showReturnMessage}
          revJet={revJet}
          showPla={showSponsoredPla}
        />
      )
        : (
          <ProductOverviewMobile
            itemId={itemId}
            showRichContentsOnly={showRichContentsOnly}
            quickLinks={quickLinks}
            hideRichContents={hideRichContents}
            preview={preview}
            showReturnMessage={showReturnMessage}
            revJet={revJet}
            showPla={showSponsoredPla}
          />
        )}
    </div>
  );
};

ProductOverviewAccordionBodyComponent.displayName = 'ProductOverviewAccordionBodyComponent';

ProductOverviewAccordionBodyComponent.dataModel = dataModel;

ProductOverviewAccordionBodyComponent.themeProps = themeProperties;

ProductOverviewAccordionBodyComponent.defaultThemeProps = defaultThemeProperties;

ProductOverviewAccordionBodyComponent.propTypes = productOverviewPropTypes;

ProductOverviewAccordionBodyComponent.defaultProps = productOverviewDefaultProps;

const ProductOverviewAccordionBody = withErrorBoundary(ProductOverviewAccordionBodyComponent);

ProductOverviewAccordionBody.displayName = 'ProductOverviewAccordionBody';

ProductOverviewAccordionBody.dataModel = dataModel;

ProductOverviewAccordionBody.propTypes = productOverviewPropTypes;

ProductOverviewAccordionBody.defaultProps = productOverviewDefaultProps;

ProductOverviewAccordionBody.themeProps = themeProperties;

ProductOverviewAccordionBody.defaultThemeProps = defaultThemeProperties;

ProductOverviewAccordionBody.dataModel = dataModel;

export { ProductOverviewAccordionBody };
